<template>
  <div>
    <v-container fluid>
      <v-row justify="center">

        <v-col cols="12" lg="8">

          <v-toolbar class="rounded-lg transparent" flat>

            <v-tabs>
              <v-tab class="b-700">
                {{ $route.params.id ? 'Modifier' : 'Ajouter' }} une campagne
              </v-tab>
            </v-tabs>

            <v-spacer/>

            <v-btn class="mt-3" color="primary"
                   depressed
                   rounded
                   @click="$router.push('/statement-campaigns')">
              <v-icon left>mdi-arrow-left</v-icon>
              Retour
            </v-btn>

          </v-toolbar>

        </v-col>

        <v-col cols="12" lg="8">

          <v-card :disabled="showLoading || supervisorsLoading" :loading="showLoading || supervisorsLoading"
                  class="rounded-lg shadow">
            <v-card-text class="pa-10">

              <v-text-field v-model.trim="form.name"
                            :error-messages="errors.name"
                            label="Nom *"></v-text-field>

              <v-autocomplete v-model="form.supervisor_ids"
                              :disabled="supervisorsLoading"
                              :error-messages="errors.supervisor_ids"
                              :items="supervisors"
                              :loading="supervisorsLoading"
                              chips
                              class="mb-2"
                              hide-details="auto"
                              item-text="name"
                              item-value="id"
                              label="Superviseurs *"
                              multiple
              >
                <template v-slot:selection="data">
                  <v-chip :input-value="data.selected"
                          close
                          color="blue"
                          dark
                          v-bind="data.attrs"
                          @click="data.select"
                          @click:close="remove(data.item)"
                  >
                    <v-avatar left>
                      <v-img v-if="data.item.photo" :src="FILE_URL + data.item.photo"></v-img>
                      <v-img v-else :src="require('@/assets/avatar.png')"></v-img>
                    </v-avatar>
                    {{ data.item.name }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar>
                      <v-img v-if="data.item.photo" :src="FILE_URL + data.item.photo"></v-img>
                      <v-img v-else :src="require('@/assets/avatar.png')"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>

              <v-btn :disabled="supervisors.length===0"
                     class="blue"
                     dark
                     depressed
                     small
                     @click="selectAllSupervisors">
                <v-icon left>
                  {{ selectAllSupervisorsIcon }}
                </v-icon>
                Sélectionner tout
              </v-btn>


              <v-menu ref="menu"
                      v-model="menu"
                      :close-on-click="false"
                      :close-on-content-click="false"
                      min-width="auto"
                      offset-y
                      transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :error-messages="errors.start_date || errors.end_date"
                                :value="form.dates.join(' ~ ')"
                                append-icon="mdi-calendar"
                                label="Planification *" readonly
                                v-bind="attrs" v-on="on">
                  </v-text-field>
                </template>
                <v-date-picker v-model="form.dates"
                               :min="date_now" range scrollable>
                  <v-spacer></v-spacer>
                  <v-btn class="rounded-lg" text
                         @click="menu = false">
                    <v-icon left>mdi-close-circle-outline</v-icon>
                    Fermer
                  </v-btn>
                  <v-btn :disabled="form.dates.length < 2" class="rounded-lg"
                         color="primary"
                         text
                         @click="menu = false">
                    <v-icon left>mdi-check-circle-outline</v-icon>
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-file-input ref="photo"
                            :clearable="false"
                            :disabled="upload_loading"
                            :error-messages="upload_errors.photos"
                            :loading="upload_loading"
                            accept="image/png,image/jpeg"
                            append-icon="mdi-image-outline"
                            chips
                            label="Photos (Tu peut selectonez plusier photos en mm temps)"
                            multiple
                            show-size
                            small-chips
                            @change="uploadPhoto($event)"
                            @click="upload_errors && upload_errors.photos ? upload_errors.photos = '' : false"></v-file-input>

              <div v-if="form.photos.length">
                <div v-for="(photo,i) in form.photos"
                     :key="i"
                     :class="$vuetify.theme.dark ? 'grey' : 'grey lighten-3'"
                     class="d-flex justify-space-between pa-3 rounded"
                     style="margin-top:-10px">
                  <v-avatar size="40"
                            @click="[image = [{src: FILE_URL + photo}],visible = true]">
                    <v-img :src="FILE_URL + photo" class="rounded cursor-pointer"/>
                  </v-avatar>
                  <v-spacer/>
                  <v-btn color="red" icon @click="removePhoto(i)">
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </div>
              </div>


              <v-textarea v-model.trim="form.description"
                          :error-messages="errors.description"
                          label="Description"
                          rows="2"></v-textarea>

              <v-autocomplete v-model="form.KPIs"
                              :error-messages="errors.KPIs"
                              :items="KPIs"
                              :loading="KPIsLoading"
                              chips
                              class="py-5"
                              hide-details="auto"
                              item-disabled="is_disabled"
                              item-text="title"
                              item-value="id"
                              label="KPI'S (optionnel)"
                              multiple
                              outlined
                              return-object
                              small-chips
              >
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content :class="data.item.is_disabled ? 'disabled-list-item' : ''">
                      <v-list-item-title v-html="data.item.title"></v-list-item-title>
                      <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text>
                        <v-chip color="blue" dark small>
                          {{ data.item.winning_points | toCurrency }}
                        </v-chip>
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </template>
                </template>
              </v-autocomplete>

              <div v-for="(kpi,i) in form.KPIs"
                   :key="i"
                   class="table-border my-4 rounded">

                <v-row align="center" class="pa-4" justify="center">

                  <v-col cols="1">
                    <v-btn color="blue" dark depressed fab x-small>{{ i + 1 }}</v-btn>
                  </v-col>

                  <v-col cols="6">
                    <strong class="d-block">{{ kpi.title }}</strong>
                    {{ kpi.description }}
                  </v-col>

                  <v-col cols="4">
                    <v-text-field v-model="kpi.winning_points"
                                  v-number
                                  :disabled="kpi.is_disabled"
                                  :error-messages="errors['KPIs.' + i + '.winning_points']"
                                  class="custom-input"
                                  hide-details="auto"
                                  label="Points gagnants *"
                                  min="0"
                                  dense
                                  outlined
                                  suffix="Pts"
                                  type="number"/>
                  </v-col>

                  <v-col cols="1">
                    <v-btn :disabled="kpi.is_disabled"
                           color="red"
                           icon
                           @click="removeKPI(i)">
                      <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </v-col>

                </v-row>
              </div>

            </v-card-text>
          </v-card>
          <div class="d-flex justify-space-between mt-3">
            <v-spacer/>

            <v-btn v-if="!$route.params.id"
                   :disabled="isLoading"
                   :loading="isLoading"
                   class="rounded-lg mr-2"
                   color="primary"
                   text
                   @click="save('new')"
            >
              <v-icon left>mdi-content-save</v-icon>
              Enregistrer et nouveau
            </v-btn>

            <v-btn :disabled="isLoading"
                   :loading="isLoading"
                   class="rounded-lg"
                   color="primary"
                   depressed
                   @click="save('back')"
            >
              <v-icon left>mdi-content-save</v-icon>
              Enregistrer <span v-if="!$route.params.id">et retour</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <!--Dialogs-->
      <vue-easy-lightbox :imgs="image"
                         :index="index"
                         :visible="visible"
                         escDisabled
                         moveDisabled
                         @hide="visible = false"></vue-easy-lightbox>
    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {

  data() {
    return {
      supervisorsLoading: false,
      supervisors: [],
      active_tab: true,

      date_now: this.$moment().format('YYYY-MM-DD'),
      menu: false,
      form: {
        id: '',
        name: '',
        description: '',
        photos: [],
        dates: [],
        supervisor_ids: [],
        KPIs: [],
      },

      //Upload image
      upload_loading: false,
      upload_errors: [],

      //validations
      isLoading: false,
      errors: {},

      //Show image
      image: '',
      visible: false,
      index: 0,
      FILE_URL: process.env.VUE_APP_FILE_URL,

      KPIsLoading: false,
      KPIs: [],

      showLoading: false,
      campaignStatus: '',
    }
  },
  methods: {
    uploadPhoto(files) {
      if (files && files.length) {
        for (let i = 0; i < files.length; i++) {
          this.upload_loading = true
          this.upload_errors = []
          let formData = new FormData()
          formData.append('photo', files[i])
          HTTP.post('/statement-campaigns/upload-image', formData).then((res) => {
            this.upload_loading = false
            this.form.photos.push(res.data.file_name)
          }).catch(err => {
            this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
            this.upload_errors = err.response.data.errors
            this.upload_loading = false
            console.log(err)
          })
        }

      }
    },
    save(type) {
      this.isLoading = true
      this.errors = {}

      let data = this.form
      if (data['dates'].length === 2) {
        data['start_date'] = data['dates'][0]
        data['end_date'] = data['dates'][1]
      }

      let url = this.form.id ? '/statement-campaigns/update' : '/statement-campaigns/store'
      HTTP.post(url, data).then(() => {
        this.isLoading = false
        this.$successMessage = 'Cette campagne a été enregistrée avec succès !'

        if (type === 'back') {
          this.$router.push('/statement-campaigns')
        } else {
          this.resetForm()
          this.$vuetify.goTo(0)
        }

      }).catch(err => {
        this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        this.isLoading = false
        this.errors = err.response.data.errors
        this.$vuetify.goTo(0)
        console.log(err)
      })
    },
    getSupervisors() {
      this.supervisorsLoading = true
      HTTP.get('/supervisors').then((res) => {
        this.supervisorsLoading = false
        this.supervisors = res.data.data
      }).catch(err => {
        this.supervisorsLoading = false
        console.log(err)
      })
    },
    remove(item) {
      const index = this.form.supervisor_ids.indexOf(item.id)
      if (index >= 0) this.form.supervisor_ids.splice(index, 1)
    },
    getKPIs() {
      this.KPIsLoading = true
      HTTP.get('/KPIs').then((res) => {
        this.KPIsLoading = false
        this.KPIs = res.data.data.map(el => {
          return {
            id: el.id,
            title: el.title,
            description: el.description,
            winning_points: el.winning_points,
            is_disabled: false,
          }
        })
      }).catch(err => {
        this.KPIsLoading = false
        console.log(err)
      })
    },
    removeKPI(i) {
      this.form.KPIs.splice(i, 1)
    },
    removePhoto(i) {
      this.form.photos.splice(i, 1)
    },
    selectAllSupervisors() {
      if (this.form.supervisor_ids.length === this.supervisors.length) {
        this.form.supervisor_ids = []
      } else {
        this.form.supervisor_ids = this.supervisors.map(el => el.id)
      }
    },
    resetForm() {
      this.form = {
        id: '',
        name: '',
        description: '',
        photos: [],
        dates: [],
        supervisor_ids: [],
        KPIs: [],
      }

      if (this.$refs.photo) {
        this.$refs.photo.reset()
      }
    },
    show() {
      this.showLoading = true
      HTTP.get('/statement-campaigns/' + this.$route.params.id + '/show').then((res) => {
        this.showLoading = false
        let campaign = res.data.data

        /**
         * Check campaign state
         */
        this.campaignStatus = campaign.remaining_days.state

        let isDisabled = false

        if (this.campaignStatus === 'in_progress') {
          isDisabled = true
        }
        if (this.campaignStatus === 'completed') {
          isDisabled = true
        }
        if (this.campaignStatus === 'waiting') {
          isDisabled = false
        }

        this.form = {
          id: campaign.id,
          name: campaign.name,
          description: campaign.description,
          photos: campaign.photos,
          dates: [campaign.start_date, campaign.end_date],
          supervisor_ids: campaign.supervisors.map(el => el.id),
          KPIs: campaign.k_p_is.map(el => {
            return {
              id: el.id,
              title: el.title,
              description: el.description,
              winning_points: el.pivot.winning_points,
              is_disabled: isDisabled,
            }
          })
        }

        /***
         * Add disabled to origin list
         */
        let disabledIds = (this.form.KPIs.filter(el => el.is_disabled === true)).map(el => el.id)

        this.KPIs.map(el => {
          let i = disabledIds.findIndex(id => id === el.id)
          if (i !== -1) {
            el['is_disabled'] = true
          }
        })

      }).catch(err => {
        this.showLoading = false
        console.log(err)
      })
    },
  },
  computed: {
    selectAllSupervisorsIcon() {
      if (this.form.supervisor_ids.length === this.supervisors.length) return 'mdi-close-box'
      if (this.form.supervisor_ids.length > 0 && this.form.supervisor_ids.length !== this.supervisors.length) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  created() {
    this.getSupervisors()
    this.getKPIs()
    if (this.$route.params.id) {
      this.show()
    }
  }
}
</script>

<style>
.theme--light.custom-input.v-input--is-disabled {
  background: #f9f9f9;
}

.theme--dark.custom-input.v-input--is-disabled {
  background: #28243d;
}

.custom-input input:disabled {
  cursor: no-drop;
}

.disabled-list-item {
  color: #9d9d9d;
}
</style>